
import { defineComponent, ref } from 'vue';

import AppTextarea from '@/components/stateless/AppTextarea.vue';

export default defineComponent({
  name: 'Textarea',

  components: { AppTextarea },

  setup () {
    const textareaDefault = ref('Textarea default');
    const textareaPlaceholder = ref('Textarea placeholder');
    const textareaResize = ref('Textarea resize');
    const textareaDisabled = ref('Textarea disabled');
    const textareaRaadonly = ref('Textarea readonly');
    const textareaError = ref('Textarea error');
    const textareaMaxLength = ref('Textarea max length');

    return {
      textareaDefault,
      textareaPlaceholder,
      textareaResize,
      textareaDisabled,
      textareaRaadonly,
      textareaError,
      textareaMaxLength
    };
  }
});
